<template>
  <div>
    <Breadcrumbs title="Teacher Settlement Report" main="Reports"/>
    <div class="container-fluid" v-if="loaded">


      <div class="card">
        <div class="card-body" v-if="settlement" id="print">

          <div class="text-center">
            <h2 class="card-title">Teacher Settlement Report</h2>
          </div>
          <b-row>
            <b-col cols="4" md="3" xl="2">Settlement ID</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ settlement.id }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" xl="2">Last Settlement ID</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ last_settlement_id || "N/A" }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" xl="2">Institute</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ settlement.institute.name  || "All Institutes" }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" xl="2">Teacher</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ settlement.user.first_name }} {{ settlement.user.last_name }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" xl="2">Cashier</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ settlement.creator.first_name || "N/A" }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" xl="2">Date&nbsp;/&nbsp;Time</b-col>
            <b-col cols="8" md="9">: {{ moment(settlement.created_at).format('lll') }}</b-col>
          </b-row>

          <div class="table-responsive datatable-vue" v-if="!isBusy">
            <b-table
                ref="table"
                show-empty
                selected-variant="success"
                :items="tableRecords"
                :fields="tableFields"
                :filter="filter"
                :current-page="1"
                :per-page="totalRows"
            >
              <template #cell(index)="row">{{ 1+row.index }}</template>
              <template #cell(created_at)="row">
                <p class="m-0">{{ moment(row.item.created_at).format('ll') }}</p>
                <p class="m-0">{{ moment(row.item.created_at).format('h:s A') }}</p>
              </template>
              <template #cell(student_name)="row">
                <div>{{ row.item.user_first_name }} {{ row.item.user_last_name }}</div>
                <div>( {{ row.item.user_nic }} )</div>
              </template>
              <template #cell(invoice_details)="row">
                <div>{{ row.item.invoice_id }}</div>
                <div>{{ moment(row.item.payment_month).format('YYYY-MM') }}</div>
              </template>
              <template #cell(settled)="row">
                <div v-if="row.item.settlement_id">YES</div>
                <div v-else>NO</div>
              </template>
            </b-table>
          </div>


          <b-row>
            <b-col cols="4" md="3" lg="2">No of Payments</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ settlement.record_count }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Institute Share</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ settlement.institute_share }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Teacher Share</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ settlement.teacher_share }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Total</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ settlement.amount }}</b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="text-center">
              <b-button @click="printReport('print')" :disabled="!totalRows || loading" size="sm" class="btn btn-square text-uppercase btn-success m-2">Print</b-button>
            </b-col>
          </b-row>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import printJS from 'print-js'
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import CRUD_API from "@/services/crud";
import { Printd } from 'printd'
export default {
  name: "settlements_show",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  mounted() { this.fetchSettlement() },
  data() {
    return {
      loaded: true,
      base_url: '/api/backend/reports/teacher-settlements',

      filter: '',
      totalRows: 0,
      perPage: 1000,
      currentPage: 1,
      lastPage: 1,
      tableRecords: [],
      tableFields: [
        { key: 'index', label: '#', sortable: false },
        // { key: 'id', label: 'Payment ID', sortable: false },
        { key: 'created_at', label: 'Date / Time', sortable: true },
        { key: 'student_name', label: 'Student', sortable: true },
        { key: 'invoice_details', label: 'Invoice', sortable: true },
        { key: 'name', label: 'Class', sortable: true },
        { key: 'payment_method', label: 'Payment Method', sortable: false, class: 'text-center' },
        { key: 'class_fee', label: 'Class Fee', sortable: false, class: 'text-right' },
        { key: 'discount', label: 'Discounts', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Payment', sortable: false, class: 'text-right' },
        { key: 'settled', label: 'Settled', sortable: false },



        // { key: 'institute_name', label: 'Institute', sortable: true },
        // { key: 'cashier_name', label: 'Cashier', sortable: true },
        // { key: 'institute_share', label: 'Institute Share', sortable: false, class: 'text-right' },
        // { key: 'teacher_share', label: 'Teacher Share', sortable: false, class: 'text-right' },
        // { key: 'amount', label: 'Net Amount', sortable: false, class: 'text-right' },
      ],
      isBusy: false,
      settlement: null,
      last_settlement_id: 0,
    }
  },
  methods: {
    checkUserPermissions,
    clearTableData() {
      this.loaded = false
      this.tableRecords = []
      this.totalRows = 0
      // this.currentPage = 1
    },
    async fetchSettlement(){
      this.settlement = null
      this.tableRecords = []
      this.loaded = false
      await CRUD_API.show(this.base_url, this.$route.params.settlementId)
          .then((response) => {
            this.settlement = response.data.data.settlement
            this.last_settlement_id = response.data.data.last_settlement
            // this.settlement = response.data
            // this.totalRows = response.data.data.payments_count
            this.currentPage = 1
            this.fetchData()
          }).catch((errors) => {
            console.log("LOADING_SETTLEMENTS", errors)
          }).finally(() => {
            // this.$refs.table.refresh()
          })
    },
    async fetchPayments(){
      await CRUD_API.index(this.base_url + '/' + this.settlement.id + '/data', {
        params: {
          page: this.currentPage,
          per_page: 500,
          status: 0,
        }
      }).then((response) => {
        this.totalRows = response.data.data.total
        this.lastPage = response.data.data.last_page
        this.tableRecords = this.tableRecords.concat(response.data.data.data)
        this.loaded = true
        this.isBusy = true
      }).catch((errors) => {
        this.isBusy = false
        console.log("LOADING_SETTLEMENTS", errors)
      }).finally(() => {
        // this.$refs.table.refresh()
      })
    },
    async fetchData() {
      this.loaded = false
      await this.clearTableData()
      // await this.fetchSettlement()
      await this.fetchPayments()
      clearTimeout()
      await setInterval(() => {
        this.loaded = true
        this.currentPage += 1
        if(this.currentPage <= this.lastPage) {
          this.fetchPayments()
        } else {
          clearTimeout()
          this.isBusy = false
        }
      }, 1000)
    },
    printReport(el) {
      const d = new Printd()
      d.print( document.getElementById(el), [
        `
          * { font-family: serif; font-size: 10px; },
          @page { margin: 2.5cm; }
          div.row > div { display: inline-block; }
          div.row { display: block; }
          h2 { font-size: 14px; text-align:center; }
          `
      ] )
    },
    print(el) {
      return printJS({
        printable: el,
        type: 'html',
        targetStyles: ['border', 'padding', 'border-bottom', 'border-top', 'background'],
        showModal: true,
        modalMessage: "Retrieving Document...",
        documentTitle: "Teacher Settlement Report",// + this.$route.params.settlementId,// + " (" + this.moment().format('l') + ")",
        onPdfOpen: true,
        base64: true
      });
    },
  }
}
</script>

<style scoped>
@media print {
  .table {
    border: 1px solid #0c0e13;
  }
}
</style>
